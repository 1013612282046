import request from '../utils/request';

export function getArticleList(param) {
    return request({
        url: '/article-list',
        method: 'GET',
        params: param
    })
}

export function saveArticle(data, id) {
    return request({
        url: '/article/'+id,
        method: 'POST',
        data
    })
}

export function deleteArtice(id) {
    return request({
        url: '/article/'+id,
        method: 'delete'
    })
}

export function setArticleSort(id, sort) {
    return request({
        url: '/article/'+id+'/sort/'+sort,
        method: 'PUT'
    })
}

export function setArticleIsvalidate(id, isvalidate) {
    return request({
        url: '/article/'+id+'/isvalidate/'+isvalidate,
        method: 'PUT'
    })
}
